/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-09-20 19:02:07
 * @LastEditors: nora tingting.xu@theone.art
 * @LastEditTime: 2022-12-20 22:29:35
 * @FilePath: /theone-chain/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from "vue-router";
const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../pages/Index.vue"),
  },
  {
    path: "/copyright",
    name: "copyright",
    component: () => import("../pages/solve/Copyright.vue"),
  },
  {
    path: "/collect",
    name: "collect",
    component: () => import("../pages/solve/Collect.vue"),
  },
  {
    path: "/safe",
    name: "safe",
    component: () => import("../pages/solve/Safe.vue"),
  },
  {
    path: "/blocks",
    name: "BlockList",
    component: () => import("../pages/block-list.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/block_info/:height",
    name: "BlockInfo",
    // component: BlockInfo,
    component: () => import("../pages/block-info.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/txs",
    name: "TransactionList",
    // component: TransactionList,
    component: () => import("../pages/transaction-list.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/transaction_info/:hash",
    name: "TransactionInfo",
    // component: TransactionInfo,
    component: () => import("../pages/transaction-info.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/nfts",
    name: "NftList",
    // component: NftList,
    component: () => import("../pages/nft-list.vue"),
    meta: {
      isShowSearch: true,
    },
  },

  {
    path: "/nft_info",
    name: "NftInfo",
    // component: NftInfo,
    component: () => import("../pages/nft-info.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/nft_info/:token_id?",
    name: "NftInfo",
    // component: NftInfo,
    component: () => import("../pages/nft-info.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/private",
    name: "Private",
    component: () => import("../pages/private.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/firm",
    name: "Firm",
    component: () => import("../pages/firm.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/chain",
    name: "Chain",
    component: () => import("../pages/Index.vue"),
  },
  {
    path: "/addressTransactionList/:address",
    name: "FromTo",
    component: () => import("../pages/from-to.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/contract/:contract?",
    name: "Contract",
    component: () => import("../pages/contract.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/empty",
    name: "Empty",
    component: () => import("../components/empty.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/emptyindex",
    name: "EmptyIndex",
    component: () => import("../components/emptyIndex.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/personcollect",
    name: "PersonCollect",
    component: () => import("../pages/user/person-collect.vue"),
    meta: {
      isShowSearch: true,
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("TOKEN");
      token ? next() : next("/chain");
    },
  },
  {
    path: "/collectdetail",
    name: "CollectDetail",
    component: () => import("../pages/user/collect-detail.vue"),
    meta: {
      isShowSearch: true,
    },
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("TOKEN");
      token ? next() : next("/chain");
    },
  },
  {
    path: "/search_identity",
    name: "SearchIdentity",
    component: () => import("../pages/identity/search-identity.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("../pages/identity/portfolio.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/purse",
    name: "Purse",
    component: () => import("../pages/identity/purse.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/opus",
    name: "Opus",
    component: () => import("../pages/identity/opus.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/block_tractionList",
    name: "blockTractionList",
    // component: TransactionList,
    component: () => import("../pages/block-tractionList.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/transc_nftList",
    name: "transcNftList",
    // component: TransactionList,
    component: () => import("../pages/transc-nftList.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/nft_trlist/:tokenId?",
    name: "nftTrlist",
    // component: TransactionList,
    component: () => import("../pages/nft-trlist.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/owner",
    name: "owner",
    // component: TransactionList,
    component: () => import("../pages/owner.vue"),
    meta: {
      isShowSearch: true,
    },
  },
  {
    path: "/introduce",
    name: "introduce",
    // component: TransactionList,
    component: () => import("../pages/introduce/index.vue"),
    meta: {
      isShowSearch: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
