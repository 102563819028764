<template>
  <div class="hea">
    <div class="header">
      <div class="left">
        <div class="left-logo">
          <a :href="masterLink">
            <img
              src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/logo.svg"
              alt=""
            />
          </a>
        </div>
      </div>

      <div class="center pc-nav">
        <div class="nav">
          <div class="nav-one">
            <span
              class="nav-one-p"
              style="color: #555555; cursor: pointer"
              @click="ongo"
            >
              解决方案
              <img
                class="editor-bot"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/small.svg"
                alt=""
                style="margin-left: 10px"
              />
              <img
                class="editor"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/editor.svg"
                alt=""
                style="margin-left: 10px"
              />
            </span>
            <div class="block">
              <div class="nav-wrap"></div>
              <ul class="block-uu">
                <li @click="copy">
                  <a :href="`${masterLink}/copyright`" style="color: #555555">
                    <img
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/copymini.svg"
                      alt=""
                    />
                    <p>版权归属</p>
                  </a>
                </li>

                <li @click="collect">
                  <div class="borderL"></div>
                  <a style="color: #555555">
                    <img
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/collectmini.svg"
                      alt=""
                    />
                    <p>数字藏品</p>
                  </a>
                  <div class="borderR"></div>
                </li>

                <li @click="safe">
                  <a style="color: #555555">
                    <img
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/safemini.svg"
                      alt=""
                    />
                    <p>信息安全</p>
                  </a>
                </li>
              </ul>
            </div>
            <!--  -->
          </div>
          <div class="nav-two">
            <router-link to="/chain" replace active-class="change" class="all">
              <span class="nav-one-p" style="color: #555555">
                链浏览器
                <img
                  class="editor-bot"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/small.svg"
                  alt=""
                  style="margin-left: 10px"
                />
                <img
                  class="editor"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/editor.svg"
                  alt=""
                  style="margin-left: 10px"
                />
              </span>
            </router-link>
            <div class="gheader">
              <GHeader></GHeader>
            </div>
          </div>
          <div class="nav-two">
            <a
              href="https://wallet.theonechain.art"
              replace
              active-class="change"
              class="all"
            >
              <span class="nav-one-p" style="color: #555555">唯艺卡包 </span>
            </a>
          </div>
          <!--          <div class="nav-three">-->
          <!--            <span class="nav-three-title" @click="showIdentity()">数字身份</span>-->
          <!--            <span class="time">-->
          <!--              &lt;!&ndash; <a-badge count="New" :number-style="{backgroundColor: 'orange',fontSize:'10px', paddingLeft: '5px', height: '15px',lineHeight: '15px' }" /> &ndash;&gt;-->
          <!--              <span class="time-tri">-->

          <!--              </span>-->
          <!--              <span class="time-new">-->
          <!--                New-->
          <!--              </span>-->
          <!--            </span>-->
          <!--          <div class="identity"  v-if="identity" @touchmove.prevent @mousewheel.prevent>-->
          <!--            <div class="identity-next">-->
          <!--              <div class="identity-next-top">-->
          <!--                <p>数字身份</p>-->
          <!--              </div>-->
          <!--              <div class="identity-next-center">-->
          <!--                <div class="identity-next-center-text">-->
          <!--                 <p class="identity-next-center-text-title">数字身份是什么？-->
          <!--                  <span></span>-->
          <!--                 </p>-->

          <!--                 <p class="identity-next-center-text-text">数字身份是一款可以解析至唯艺区块链钱包、数字藏品和元宇宙空间的基于区块链技术的数字身份服务。</p>-->
          <!--                </div>-->
          <!--                <div class="identity-next-center-text">-->
          <!--                  <p class="identity-next-center-text-title">数字身份的优势？-->
          <!--                    <span></span>-->
          <!--                  </p>-->
          <!--                 <p class="identity-next-center-text-text">区块链钱包地址的组成字符串过长，会造成不易记忆、不好确认的问题，因此我们准备了更加容易记忆且突出Web3.0特点的数字身份服务助您使用。</p>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="identity-next-bottom">-->
          <!--                <div class="identity-next-bottom-click">-->
          <!--                  <p @click="next()">下一步</p>-->
          <!--                </div>-->

          <!--              </div>-->
          <!--              &lt;!&ndash; <div class="identity-next-close">-->
          <!--                <img src="../../assets/image/icon/close.svg" alt="">-->
          <!--              </div> &ndash;&gt;-->
          <!--              <div class="identity-next-img">-->
          <!--                <img src="../../assets/image/next.png" alt="">-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <div class="identity-box">-->
          <!--              <div class="identity-box-top">-->
          <!--                <p>如何获取我的数字身份?</p>-->
          <!--              </div>-->
          <!--              <div class="identity-box-center">-->
          <!--                <div class="identity-box-center-text">-->
          <!--                  · 若您已经获得了数字身份，请解锁您的数字身份-->
          <!--                </div>-->
          <!--                <div class="identity-box-center-text">-->
          <!--                  · 若您感兴趣且还未获得，请前往-->
          <!--                  <a href="" style=" color: #436BED; "> 唯艺云</a>-->
          <!--                  生成你的数字身份吧-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="identity-box-bottom">-->
          <!--                <div class="identity-box-bottom-click">-->
          <!--                  <p>解锁数字身份</p>-->
          <!--                </div>-->

          <!--              </div>-->
          <!--              &lt;!&ndash; <div class="identity-box-close">-->
          <!--                <img src="../../assets/image/icon/close.svg" alt="">-->
          <!--              </div> &ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          </div>-->
        </div>
      </div>
      <div class="right">
        <!-- 移动端登录信息 -->
        <div class="right-net phone-nav">
          <!-- <button @click="chack">{{text}}</button> -->

          <div v-if="loginsave">
            <div
              class="right-net-login"
              @click="showModal"
              v-if="flag == false"
            >
              登录
            </div>

            <!-- 头部 -->
            <div class="right-net-login" v-if="flag == true">
              <div class="right-net-login-avatar">
                <a-avatar
                  :src="userMess.avatar || userInfo.avatar"
                  :size="32"
                />
              </div>
              <div class="avatar-right-img">
                <img
                  v-show="avatarRight"
                  @click="avatarRight = false"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/topLogo.png"
                  alt=""
                />
                <img
                  v-show="!avatarRight"
                  @click="avatarRight = true"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/smallBottomlogo.png"
                  alt=""
                />
              </div>
              <div v-show="avatarRight" class="right-net-login-block">
                <div class="right-net-login-block-top user-block">
                  <!-- <div><img :src="userInfo.avatar" alt=""></div> -->
                  <div class="right-net-login-block-top-avatar">
                    <a-avatar
                      :src="userMess.avatar || userInfo.avatar"
                      :size="34"
                    />
                  </div>
                  <div class="right-net-login-block-top-name user-detail-wrap">
                    <div class="right-net-login-block-top-name-title">
                      {{ userMess.nickname || userInfo.nickname }}
                    </div>
                    <div>
                      <div class="item-line">
                        <span class="icon icon-phone"></span
                        >{{ phonesave(userMess.phone) }}
                      </div>
                      <div class="item-line">
                        <span class="key">唯艺链地址：</span
                        ><span
                          v-if="userMess.walletAddress"
                          @click="copyToClip(userMess.walletAddress)"
                        >
                          <span class="blue">{{
                            formatPath(userMess.walletAddress)
                          }}</span>
                          <span class="icon icon-copy"></span>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <!-- <div class="right-net-login-block-top-name-saying"> -->
                    <!-- 没什么想说的 -->
                    <!-- </div> -->
                  </div>
                </div>

                <div class="right-net-login-block-bottom">
                  <div class="btn-group">
                    <div class="btn btn-primary" @click="goself">个人中心</div>

                    <div class="btn btn-default" @click="loginout">
                      退出登录
                    </div>
                  </div>
                </div>
                <div class="right-net-login-block-mask"></div>
              </div>
              <!-- <div>
            <img src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/collectmini.png" alt="">
          </div> -->
            </div>
          </div>
          <div v-if="!loginsave">
            <div class="right-net-login"></div>
          </div>
          <div class="right-net-one">
            <div class="nav-icon">
              <!-- <Hamburger @click="onToggleNav"></Hamburger> -->
              <img
                v-show="!isShowNav"
                @click="onToggleNav"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/mockplus.png"
                alt=""
              />
              <img
                v-show="isShowNav"
                @click="onToggleNav"
                src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/close.png"
                alt=""
              />
            </div>
            <div></div>
          </div>
          <div
            class="detail-masker"
            v-if="visible"
            @touchmove.prevent
            @mousewheel.prevent
          >
            <div class="detail-masker-box">
              <div class="top-box">
                <div class="top-box-top">
                  <div class="top-box-top-left">
                    <div class="top-box-top-left-imglogo">
                      <img
                        src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/imglogo.png"
                        alt=""
                      />
                    </div>
                    <span>授权登录</span>
                  </div>
                  <div class="top-box-right" @click="handleCancel">
                    <img
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/close.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="detail-masker-box-left1">
                  <!-- <img src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/Figure.png"
                  alt="" /> -->
                  <img
                    src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/Figure1.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="detail-masker-box-right">
                <div class="detail-masker-box-right-center">
                  <p class="detail-masker-box-right-center-title">温馨提示:</p>
                  <div class="detail-masker-box-right-center-box">
                    <p class="detail-masker-box-right-center-text">
                      在完成登录前，您需要进行唯一艺术账号的授权。
                    </p>
                    <p class="detail-masker-box-right-center-text">
                      ·
                      若您没有唯一艺术的账号，点击【去授权】跳转后进行新账号的注册；
                    </p>
                    <p class="detail-masker-box-right-center-text">
                      ·
                      若您拥有唯一艺术的账号，则点击【去授权】跳转后登录并授权；
                    </p>
                  </div>
                </div>
                <div class="detail-masker-box-right-bottom">
                  <div
                    class="detail-masker-box-right-bottom-cancel"
                    @click="handleCancel"
                  >
                    取消
                  </div>

                  <div
                    class="detail-masker-box-right-bottom-confirm"
                    @click="handleOk"
                  >
                    去授权
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="right-net pc-nav">
          <!-- <button @click="chack">{{text}}</button> -->
          <div class="right-net-one">
            <div>
              <a>
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/global.svg"
                  alt=""
                  style="margin-right: 8px"
                />
                <span v-if="number == 1"> 主网 </span>
                <span v-else> 测试网 </span>
                <!-- <down-outlined />  -->
                <img
                  class="small-bot"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/editor.svg"
                  alt=""
                  style="margin-left: 8px"
                  v-if="number == 1"
                />
                <img
                  class="small-bot"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/editor.svg"
                  alt=""
                  v-else
                />

                <img
                  class="small"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/small.svg"
                  alt=""
                  style="margin-left: 8px"
                  v-if="number == 1"
                />
                <img
                  class="small"
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/small.svg"
                  alt=""
                  v-else
                />
              </a>
            </div>

            <div class="right-net-one-block">
              <ul class="right-net-one-block-ll">
                <!-- <li><a class="chick" href="http://101.200.196.3:44444/#/chain">主网</a></li> -->
                <li><a class="chick" :href="main">主网</a></li>
                <li><a class="chick" :href="qa">测试网</a></li>
                <!-- <li><a class="chick" href="http://101.200.143.140:55555/#/chain">测试网</a></li> -->
              </ul>
            </div>
          </div>
          <div v-if="loginsave">
            <div
              class="right-net-login"
              @click="showModal"
              v-if="flag == false"
            >
              登录
            </div>
            <div class="right-net-login" v-if="flag == true">
              <div class="right-net-login-avatar">
                <a-avatar
                  :src="userMess.avatar || userInfo.avatar"
                  :size="32"
                />
              </div>
              <div class="right-net-login-block">
                <div class="right-net-login-block-top user-block">
                  <!-- <div><img :src="userInfo.avatar" alt=""></div> -->
                  <div class="right-net-login-block-top-avatar">
                    <a-avatar
                      :src="userMess.avatar || userInfo.avatar"
                      :size="40"
                    />
                  </div>
                  <div class="right-net-login-block-top-name user-detail-wrap">
                    <div class="right-net-login-block-top-name-title user-name">
                      {{ userMess.nickname || userInfo.nickname }}
                    </div>
                    <div>
                      <div class="item-line">
                        <span class="icon icon-phone"></span
                        >{{ phonesave(userMess.phone) }}
                      </div>
                      <div class="item-line">
                        <span class="key">唯艺链地址：</span
                        ><span
                          v-if="userMess.walletAddress"
                          @click="copyToClip(userMess.walletAddress)"
                        >
                          <span class="blue">{{
                            formatPath(userMess.walletAddress)
                          }}</span>
                          <span class="icon icon-copy"></span>
                        </span>
                        <span v-else>--</span>
                      </div>
                    </div>
                    <!-- <div class="right-net-login-block-top-name-saying"> -->
                    <!-- 没什么想说的 -->
                    <!-- </div> -->
                  </div>
                </div>
                <div class="head-line"></div>
                <div class="right-net-login-block-bottom">
                  <div class="btn-group">
                    <div class="btn btn-primary" @click="goself">个人中心</div>

                    <div class="btn btn-default" @click="loginout">
                      退出登录
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div>
            <img src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/collectmini.png" alt="">
          </div> -->
            </div>
          </div>
          <div v-if="!loginsave">
            <div class="right-net-login"></div>
          </div>
          <div
            class="detail-masker"
            v-if="visible"
            @touchmove.prevent
            @mousewheel.prevent
          >
            <div class="detail-masker-box">
              <div class="detail-masker-box-left">
                <img
                  src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/Figure.png"
                  alt=""
                />
              </div>
              <div class="detail-masker-box-right">
                <div class="detail-masker-box-right-top">
                  <div class="detail-masker-box-right-top-left">
                    <div class="detail-masker-box-right-top-left-imglogo">
                      <img
                        src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/imglogo.png"
                        alt=""
                      />
                    </div>
                    <span>授权登录</span>
                  </div>
                  <div
                    class="detail-masker-box-right-top-right"
                    @click="handleCancel"
                  >
                    <img
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/close.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="detail-masker-box-right-center">
                  <p class="detail-masker-box-right-center-title">温馨提示:</p>
                  <div class="detail-masker-box-right-center-box">
                    <p class="detail-masker-box-right-center-text">
                      在完成登录前，您需要进行唯一艺术账号的授权。
                    </p>
                    <p class="detail-masker-box-right-center-text">
                      ·
                      若您没有唯一艺术的账号，点击【去授权】跳转后进行新账号的注册；
                    </p>
                    <p class="detail-masker-box-right-center-text">
                      ·
                      若您拥有唯一艺术的账号，则点击【去授权】跳转后登录并授权；
                    </p>
                  </div>
                </div>
                <div class="detail-masker-box-right-bottom">
                  <div
                    class="detail-masker-box-right-bottom-cancel"
                    @click="handleCancel"
                  >
                    取消
                  </div>

                  <div
                    class="detail-masker-box-right-bottom-confirm"
                    @click="handleOk"
                  >
                    去授权
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="phone-nav">
          <transition name="fade">
            <div class="mask" @click="onToggleNav" v-if="isShowNav"></div>
          </transition>
          <transition name="list">
            <div class="nav-content-box" v-show="isShowNav">
              <div class="nav-content">
                <a class="chick" v-if="number == 1" :href="qa">
                  <div
                    class="nav-content-title"
                    style="height: 56px; line-height: 56px; border-top: 0"
                  >
                    <div class="nav-content-title1">
                      <span style="width: 64px" :href="qa"> 测试网 </span>
                      <div class="chainLink">
                        <img
                          src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/change.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </a>
                <a class="chick" v-else :href="main">
                  <div
                    class="nav-content-title"
                    style="height: 56px; line-height: 56px; border-top: 0"
                  >
                    <div class="nav-content-title1">
                      <span style="width: 64px" :href="main"> 主网 </span>
                      <div class="chainLink">
                        <img
                          src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/change.png"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </a>
                <router-link to="/">
                  <div class="nav-content-title">
                    <span style="color: #6696fd"> 唯艺链 </span>
                    <!-- <div class="chainLink">
                    <img src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/rightLogo.png" alt="">        
                 </div> -->
                  </div>
                </router-link>
                <div
                  class="nav-content-title"
                  @click="solveIsshow = !solveIsshow"
                >
                  <span style="width: 64px"> 解决方案</span>
                  <div class="chainLink">
                    <img
                      v-show="!solveIsshow"
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/bigerTopLogo.png"
                      alt=""
                    />
                    <img
                      v-show="solveIsshow"
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/bottomLogo.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="solve-box" v-show="solveIsshow">
                  <router-link
                    :to="item.path"
                    v-for="(item, idx) in solveList"
                    :key="idx"
                  >
                    <div
                      class="nav"
                      :class="{ active: item.path == currentPath }"
                    >
                      <img
                        :src="`https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/${item.img}`"
                        alt=""
                        style="width: 32px; height: 32px"
                      />
                      {{ item.name }}
                    </div>
                  </router-link>
                </div>
                <div
                  class="nav-content-title"
                  style="border-bottom: 1px solid rgba(243, 244, 247, 1)"
                  @click="chainIsShow = !chainIsShow"
                >
                  <span style="width: 64px"> 链浏览器 </span>
                  <div class="chainLink">
                    <img
                      v-show="!chainIsShow"
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/bigerTopLogo.png"
                      alt=""
                    />
                    <img
                      v-show="chainIsShow"
                      src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/bottomLogo.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="chain-box" v-show="chainIsShow">
                  <router-link to="/chain">
                    <div class="nav">
                      <span class="home"> 链浏览器首页 </span>
                      <!-- <img src="https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/rightLogo.png" alt="" style="margin-left:12px;"> -->
                    </div>
                  </router-link>
                  <router-link
                    :to="item.path"
                    v-for="(item, idx) in navList"
                    :key="idx"
                  >
                    <div
                      class="nav"
                      :class="{ active: item.path == currentPath }"
                    >
                      <img
                        :src="`https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/${item.img}`"
                        alt=""
                        style="width: 32px; height: 32px"
                      />
                      {{ item.name }}
                    </div>
                  </router-link>
                </div>
                <div
                  class="nav-content-title"
                  style="border-bottom: 1px solid rgba(243, 244, 247, 1)"
                  @click="goWyBag"
                >
                  <span style="width: 64px"> 唯艺卡包 </span>
                </div>
                <!-- <div class="nav-content-title " >
                  <span style="width: 80px;" v-if="number == 1" :href="qa"> <a class="chick" :href="qa">切换到测试网</a> </span>
                  <span style="width: 80px;" v-else :href="main"> <a class="chick" :href="main">切换到主网</a></span>
                </div> -->
                <!--                <div class="nav-content-identity" @click="showIdentity()">-->
                <!--                  <span>-->
                <!--                  数字身份-->
                <!--                  </span>-->
                <!--                </div>-->
              </div>
            </div>
          </transition>
          <div
            class="identity"
            v-if="identity"
            @touchmove.prevent
            @mousewheel.prevent
          >
            <div class="identity-next" id="next">
              <div class="identity-next-top">
                <p>数字身份</p>
              </div>
              <div class="identity-next-center">
                <div class="identity-next-center-text">
                  <p class="identity-next-center-text-title">
                    数字身份是什么？
                    <span></span>
                  </p>

                  <p class="identity-next-center-text-text">
                    数字身份是一款可以解析至唯艺区块链钱包、数字藏品和元宇宙空间的基于区块链技术的数字身份服务。
                  </p>
                </div>
                <div class="identity-next-center-text">
                  <p class="identity-next-center-text-title">
                    数字身份的优势？
                    <span></span>
                  </p>
                  <p class="identity-next-center-text-text">
                    区块链钱包地址的组成字符串过长，会造成不易记忆、不好确认的问题，因此我们准备了更加容易记忆且突出Web3.0特点的数字身份服务助您使用。
                  </p>
                </div>
              </div>
              <div class="identity-next-bottom">
                <div class="identity-next-bottom-click">
                  <p @click="nextphone()">下一步</p>
                </div>
              </div>
              <!-- <div class="identity-next-close">
                <img src="../../assets/image/icon/close.svg" alt="">
              </div> -->
              <div class="identity-next-img">
                <img src="../../assets/image/next.png" alt="" />
              </div>
            </div>
            <div class="identity-box" id="box">
              <div class="identity-box-top">
                <p>如何获取我的数字身份?</p>
              </div>
              <div class="identity-box-center">
                <div class="identity-box-center-text">
                  · 若您已经获得了数字身份，请解锁您的数字身份
                </div>
                <div class="identity-box-center-text">
                  · 若您感兴趣且还未获得，请前往
                  <a href="" style="color: #436bed"> 唯艺云</a>
                  生成你的数字身份吧
                </div>
              </div>
              <div class="identity-box-bottom">
                <div class="identity-box-bottom-click">
                  <p>解锁数字身份</p>
                </div>
              </div>
              <!-- <div class="identity-box-close">
                <img src="../../assets/image/icon/close.svg" alt="">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { DownOutlined, GlobalOutlined } from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
import { $login } from "../../api/login";
import { $userInfo } from "../../api/userinfo";
import { onMounted, ref, defineProps, reactive, computed, watch } from "vue";
import GHeader from "../g-header.vue";
import { message, Modal } from "ant-design-vue";
import { useRoute } from "vue-router";

import { formatPath, phonesave } from "@/utils/tool";
import Hamburger from "@/components/global/hamburger.vue";
import {
  setToken,
  saveUserInfo,
  removeToken,
  removeUserInfo,
  getUserInfo,
  getToken,
  setIdentity,
  getIdentity,
} from "../../utils/author";
import { useStore } from "vuex";
import useClipboard from "vue-clipboard3";
const store = useStore();
const router = useRouter();
let flag = ref(false);
const loading = ref(false);
const visible = ref(false);
const identity = ref(false);
const main = ref(process.env.VUE_APP_MAIN);
const qa = ref(process.env.VUE_APP_QA);
const masterLink = ref(process.env.VUE_APP_MASTER);
const code = ref("");
const number = ref(process.env.VUE_APP_FLAG);
const spinning = ref(false);
const authorizationaddress = ref(process.env.VUE_APP_AUTHORIZATIONADDRESS);
const loginsave = ref(process.env.VUE_APP_LOGIN);
const avatarRight = ref(false);
const chainIsShow = ref(false);
const solveIsshow = ref(false);
const { toClipboard } = useClipboard();

let strUserInfo = reactive({
  nickname: "",
  phone: "",
  avatar: "",
});
let userInfo = reactive({
  nickname: "",
  phone: "",
  avatar: "",
});

let userMess = reactive({
  nickname: "",
  phone: "",
  avatar: "",
});
const copyToClip = async (item) => {
  console.log(`copyToClip->`, typeof item);
  try {
    await toClipboard(item.toString());
    message.success(
      {
        content: () => "复制成功",
        class: "custom-class",
        style: {
          marginTop: "50px",
        },
      },
      [1]
    );
  } catch (e) {
    console.error(e);
  } finally {
  }
};
const test = () => {};
const goWyBag = () => {
  window.location.href = `https://wallet.theonechain.art`;
};
const showModal = () => {
  visible.value = true;
  console.log(`output->login`);
};
const showIdentity = () => {
  if (getIdentity()) {
    router.push({
      name: "SearchIdentity",
    });
  } else {
    store.commit("setPhoneNav", !store.state.isShowPhoneNav);
    setIdentity("1234");
    identity.value = true;
    console.log("77777");
  }
};
const next = () => {
  var next = document.querySelector(".identity-next");
  var skip = document.querySelector(".identity-box");
  next.style.display = "none";
  skip.style.display = "flex";
  console.log("数字身份");
};
const nextphone = () => {
  var next = document.getElementById("next");
  var skip = document.getElementById("box");
  next.style.display = "none";
  skip.style.display = "flex";
  console.log("shushu");
};
const ongo = () => {
  window.location.href = "https://theonechain.art/";
};
onMounted(() => {
  console.log(`output->process.env.VUE_APP_MAIN}`, process.env.VUE_APP_MAIN);
  getUrlCode();
  console.log(`output->code.value类型`, code.value);
  // howTime();

  login();
  checkRoute(route);
});
// 上新样式
const howTime = () => {
  // 十四天后的时间
  let date = "2022-11-23 16:07:52";
  //获取到的时间(后台返回创建的时间)
  var nowTime = "2022-11-23 16:05:52";
  const thisTime = new Date(nowTime.replace(/-/g, "/")).getTime();
  const newDate = new Date(date.replace(/-/g, "/")).getTime();
  console.log(thisTime);
  let time = newDate - thisTime;
  let iDays = parseInt(time / 86400000);
  if (iDays > 1) {
    let div = document.querySelector(".time");
    console.log(div);
    div.style.display = "none";
  } else {
    let div = document.querySelector(".time");
    console.log("lalalal");
    div.style.display = "inline-flex";
  }
};

const login = async () => {
  const loginHandle = async () => {
    flag.value = false;
    const res = await $login({
      code: code.value,
    });
    console.log("loginData.data->", res.data);
    if (res.code == 200) {
      flag.value = true;

      console.log(`output->33333`);
      setToken(res.data.token);
      saveUserInfo(res.data.userInfo);
      strUserInfo = JSON.parse(window.localStorage.getItem("USERINFO"));
      userInfo.nickname = res.data.userInfo.nickname;
      userInfo.phone = res.data.userInfo.phone;
      userInfo.avatar = res.data.userInfo.avatar;
      userMess.walletAddress = res.data.userInfo.walletAddress;
      userMess.phone = userInfo.phone;
      if (!res.data.userInfo.walletAddress) {
        Modal.confirm({
          title: "登录失败",
          content: "您在唯一艺术还没有进行实名认证，所以暂未生成区块链地址",
          wrapClassName: "custom-confirm no-cancel",
          centered: true,
          closable: true,
          icon: true,
          okText: "前往认证",

          onOk: function () {
            window.location.href = `${process.env.VUE_APP_THEONE}/mine/setting/index?page=security`;
          },
        });
      }

      console.log(`output->getUserInfo().avatar`, strUserInfo);
      console.log(`output->userInfo.avatar`, userInfo.avatar);
    } else {
      // message.error(res.msg);
    }
  };
  // 登录
  if (getToken()) {
    flag.value = false;
    spinning.value = true;
    console.log(`output->17307`);
    const mess = await $userInfo({
      token: getToken(),
    });

    console.log("mess.data->", mess.data);
    if (mess.code == 200) {
      flag.value = true;
      spinning.value = false;
      userMess.nickname = mess.data.nickname;
      userMess.phone = mess.data.phone;
      userMess.avatar = mess.data.avatar;
      userMess.walletAddress = mess.data.walletAddress;
      console.log(mess);

      console.log(`output->userMessLogin`, userMess);
    } else if (mess.code == 5) {
      loginHandle();
      // message.error(res.msg);
    }
  } else {
    loginHandle();
  }
};

function getUrlCode() {
  let url = location.search;
  if (url.indexOf("?") != -1) {
    var str = url.substr(6);
    console.log(`output->str`, str);
    code.value = str;
    console.log(`output->code`, typeof code.value);
  }
}
const handleOk = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
    visible.value = false;
    console.log(
      `output->authorizationaddress.value`,
      authorizationaddress.value
    );
    window.location.href = authorizationaddress.value;
  }, 0);
};

const handleCancel = () => {
  visible.value = false;
};
const goself = () => {
  avatarRight.value = false;
  router.push({
    name: "PersonCollect",
  });
};
const loginout = () => {
  Modal.confirm({
    title: "提示",
    content: "尊敬的用户您好，确定退出登录吗？",
    wrapClassName: "custom-confirm no-cancel",
    centered: true,
    closable: true,
    icon: true,
    okText: "确认",

    onOk: function () {
      removeToken();
      removeUserInfo();
      flag.value = false;
      avatarRight.value = false;
      router.push("/chain");
    },
  });
};
function copy() {
  router.push({
    name: "copyright",
  });
  document.querySelector(".nav-one-p").style.color = "black";
  document.querySelector(".nav-one-p").style.borderRadius = "3px";
}
function collect() {
  window.location.href = `${masterLink.value}/collect`;
  /*  router.push({
    name: "collect",
  }); */
  document.querySelector(".nav-one-p").style.color = "black";
  document.querySelector(".nav-one-p").style.borderRadius = "3px";
}
function safe() {
  /*   router.push({
    name: "safe",
  }); */
  window.location.href = `${masterLink.value}/safe`;
  document.querySelector(".nav-one-p").style.color = "black";
  document.querySelector(".nav-one-p").style.borderRadius = "3px";
}

const route = useRoute();

const isShowSearch = ref(true);
const currentPath = ref("/");

const isShowNav = computed(() => {
  return store.state.isShowPhoneNav;
});
const solveList = ref([
  {
    name: "版权归属",
    path: "/copyright",
    img: "copymini.svg",
  },
  {
    name: "数字藏品",
    path: "/collect",
    img: "collectmini.svg",
  },
  {
    name: "信息安全",
    path: "/safe",
    img: "safemini.svg",
  },
]);
const navList = ref([
  {
    name: "区块",
    path: "blocks",
    img: "block.svg",
  },
  {
    name: "事务",
    path: "/txs",
    img: "trac.svg",
  },
  {
    name: "NFT",
    path: "/nfts",
    img: "nft.svg",
  },
]);
watch(
  () => route,
  (newRoute) => {
    checkRoute(newRoute);
  },
  {
    deep: true,
  }
);

const onToggleNav = () => {
  store.commit("setPhoneNav", !store.state.isShowPhoneNav);
};
const checkRoute = (route) => {
  isShowSearch.value = route.meta.isShowSearch;
  currentPath.value = route.path;
};
defineExpose({
  flag,
});
</script>

<style lang="scss" scoped>
@import "../../assets/style/header.scss";
.head-line {
  height: 1px;
  background: rgba(190, 207, 234, 0.6);
  opacity: 0.5;
  backdrop-filter: blur(19px);
  width: 80%;
  margin: 0 auto;
}
.item-line {
  display: flex;
  align-items: center;
  .phone {
    font-size: 12px;
    font-weight: 500;
    color: #596a90;
  }
  .blue {
    font-size: 14px;
    font-weight: 500;
    color: #436bed;
  }
  .key {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
  }
  .icon-copy {
    position: relative;
    top: 3px;
  }
}
.user-detail-wrap {
  padding-left: 12px;
  .item-line {
    margin-bottom: 12px;
  }
  .user-name {
    margin-bottom: 8px;
    text-align: left;
  }
}
.user-block {
  padding-top: 16px;
}
.header .right-net-login-block-top-avatar {
  width: initial;
  height: initial;
}
.header {
}

.right-net-login-block-bottom {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.icon-phone {
  background-image: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/icon/icon-phone.png");
}
.icon-copy {
  background-image: url("https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/icon/icon-copy.png");
}
.btn-group {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 540px) {
    flex: 1;
    justify-content: space-around;
  }
  .btn {
    width: 133px;
    height: 32px;
    line-height: 32px;
    text-align: center;

    border-radius: 4px;
    @media screen and (max-width: 540px) {
      width: 45%;
      height: 40px;
      line-height: 40px;
    }
  }
  .btn-primary {
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    background: linear-gradient(145deg, #74cdff 0%, #436bed 100%);
  }
}
@media screen and (max-width: 540px) {
  .right-net-login-block-top {
    background-color: #fff;
  }
  .right-net-login-block-bottom {
    background-color: #fff;
  }
  .header {
    .user-block {
      align-items: flex-start;
    }
    .right-net-login-block-top-name-title {
      text-align: left;
      margin: 0;
      margin-bottom: 5px;
    }
    .item-line {
      color: #596a90;
    }
    .user-detail-wrap {
      line-height: initial;
    }
  }
}
</style>
