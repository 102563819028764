/*
 * @Author: xutingting tingting.xu@theone.art
 * @Date: 2022-07-11 15:36:39
 * @LastEditors: xutingting tingting.xu@theone.art
 * @LastEditTime: 2022-11-10 18:17:59
 * @FilePath: /theone-chain-explorer-frontend-main/src/utils/tool.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { message } from "ant-design-vue";
import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard();
Date.prototype.Format = function (fmt) {
  //
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "H+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

export const os = () => {
  var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return {
    isTablet: isTablet,
    isPhone: isPhone,
    isAndroid: isAndroid,
    isPc: isPc,
    isChrome: isChrome,
  };
};

export const formatDate = (date, str) => {
  if (!date) return "";
  return new Date(Number(date) * 1000).Format(
    str ? str : "yyyy-MM-dd HH:mm:ss"
  );
};

export const fiflers = (val) => {
  if (!val) return "";
  return `${val.substring(0, 8)}...${val.substring(val.length - 6)}`;
};

export const iner = (val) => {
  if (!val) return "";
  return `${val.substring(0, 3)}-${val.substring(val.length - 3)}`;
};

export const phonesave = (val) => {
  if (!val) return "";
  return `${val.substring(0, 3)}****${val.substring(val.length - 4)}`;
};
export const fiflerx = (val) => {
  if (!val) return "";
  return `${val.substring(0, 6)}...${val.substring(val.length - 6)}`;
};

// 复制功能插入
export const copyToClip = async (item) => {
  console.log(`copyToClip->tool`, item);
  try {
    await toClipboard(item.toString());
    // message.success('复制成功');
    message.success({
      content: () => "复制成功",
      class: "custom-class",
      style: {
        marginTop: "50px",
      },
    });
  } catch (e) {
    console.error(e);
  }
};
export const formatPath = (path) => {
  const spliceStr = (value, num1, num2) => {
    return (
      value.substring(0, num1) +
      "..." +
      value.substring(Math.abs(value.length - num2), value.length)
    );
  };

  if (path.search(/^0x/gi) == -1) {
    return spliceStr(path, 4, 4);
  } else {
    return spliceStr(path, 6, 4);
  }
};
