<template>
  <div class="header-body">
    <div class="head-body">
      <div class="right">
        <div class="nav-icon">
          <g-hamburger @click="onToggleNav"></g-hamburger>
        </div>

        <div class="nav-list-wrap">
          <div class="pc-nav">
            <div class="nav-content">
              <router-link
                :to="item.path"
                v-for="(item, idx) in navList"
                :key="idx"
                class="nav-content-a"
              >
                <img
                  :src="`https://theoneart-public.oss-cn-qingdao.aliyuncs.com/blockchain-explorer/image/icon/${item.img}`"
                  alt=""
                  style="width: 32px; height: 32px"
                />
                <div class="nav" :class="{ active: item.path == currentPath }">
                  {{ item.name }}
                </div>
              </router-link>
            </div>
          </div>

          <div class="phone-nav">
            <transition name="fade">
              <div class="mask" @click="onToggleNav" v-if="isShowNav"></div>
            </transition>
            <transition name="list">
              <div class="nav-content" v-show="isShowNav">
                <router-link
                  :to="item.path"
                  v-for="(item, idx) in navList"
                  :key="idx"
                >
                  <div
                    class="nav"
                    :class="{ active: item.path == currentPath }"
                  >
                    {{ item.name }}
                  </div>
                </router-link>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="placehalder"></div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, computed } from "vue";
import { useStore } from "vuex";
const store = useStore();

import { os } from "../utils/tool";
import { useRoute } from "vue-router";
const route = useRoute();

const isShowSearch = ref(true);
const currentPath = ref("/");

const isShowNav = computed(() => {
  return store.state.isShowPhoneNav;
});
const navList = ref([
  {
    name: "区块",
    path: "/blocks",
    img: "block.svg",
  },
  {
    name: "事务",
    path: "/txs",
    img: "trac.svg",
  },
  {
    name: "NFT",
    path: "/nfts",
    img: "nft.svg",
  },
]);
watch(
  () => route,
  (newRoute) => {
    checkRoute(newRoute);
  },
  {
    deep: true,
  }
);
onMounted(() => {
  checkRoute(route);
});
const onToggleNav = () => {
  store.commit("setPhoneNav", !store.state.isShowPhoneNav);
};
const checkRoute = (route) => {
  isShowSearch.value = route.meta.isShowSearch;
  currentPath.value = route.path;

  // eslint-disable-next-line no-empty
  if (os().isPc) {
  } else {
    if (store.state.isShowPhoneNav) {
      store.commit("setPhoneNav", false);
    }
  }
};
</script>

<style lang="scss" scoped>
.pc-nav {
  display: block;
}
.phone-nav {
  display: none;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.36s ease-out;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.36s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.placehalder {
  height: 6px;
}
.header-body {
  // width: 80vw;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  height: 84px;
  display: flex;
  flex-wrap: wrap;
  border-radius: 2px;
  backdrop-filter: blur(9px);
  //  background: rgba(255,255,255,0.5);
  //  flex: 1;
  justify-content: center;
  align-items: center;
}
.head-body {
  // z-index: 100;
  top: 20px;
  left: 0;
  display: flex;
  // justify-content: center;
  // height: 50px;
  align-items: center;
  .right {
    flex: 1;
    .nav-icon {
      display: none;
    }
    .nav-list-wrap {
      .nav-content {
        display: flex;
        // justify-content: flex-end;
        // justify-content: center;
        align-items: center;
        //  background: rgba(255,255,255,0.5);
        &-a {
          margin-left: 126px;
          &:nth-child(1) {
            margin-left: -3px;
          }
          display: flex;
          img {
            width: 32px;
            height: 32px;
            margin-top: 20px;
            // border: 2px dashed rgba(0, 0, 0, 0.16);
            margin-left: 10px;
          }
        }
        &-a:hover {
          // background: rgba(67,107,237,0.08);
          // border-radius: 4px;
        }
        &-a.active {
          //  background: rgba(67,107,237,0.08);
          // border-radius: 4px;
        }
      }
      .nav {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #333333;
        font-weight: 500;
        border-radius: 3px;
        padding: 5px 10px 5px 10px;
        transition: all ease-in 0.16s;
        margin-left: 16px;
        // &:hover {
        //   // background: #f7f7f8;
        //   background: #F2F2F2;
        // }
        // &.active {
        //   // background: #f7f7f8;
        //    background: #F2F2F2;
        // }
      }
    }
  }
}
@media screen and (max-width: 540px) {
  .pc-nav {
    display: none;
  }
  .phone-nav {
    display: block;
  }
  .list-enter-active,
  .list-leave-active {
    transition: all 0.36s ease-out;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(100%);
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.36s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
  .placehalder {
    height: 6px;
  }
  .header-body {
    // width: 80vw;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    height: 84px;
    display: flex;
    flex-wrap: wrap;
    border-radius: 2px;
    backdrop-filter: blur(9px);
    //  background: rgba(255,255,255,0.5);
    //  flex: 1;
    justify-content: center;
    align-items: center;
  }
  .head-body {
    // z-index: 100;
    top: 20px;
    left: 0;
    display: flex;
    // justify-content: center;
    // height: 50px;
    align-items: center;
    .right {
      flex: 1;
      .nav-icon {
        display: none;
      }
      .nav-list-wrap {
        .nav-content {
          display: flex;
          // justify-content: flex-end;
          // justify-content: center;
          align-items: center;
          //  background: rgba(255,255,255,0.5);
          &-a {
            margin-left: 126px;
            &:nth-child(1) {
              margin-left: -3px;
            }
            display: flex;
            img {
              width: 32px;
              height: 32px;
              margin-top: 20px;
              // border: 2px dashed rgba(0, 0, 0, 0.16);
              margin-left: 10px;
            }
          }
          &-a:hover {
            // background: rgba(67,107,237,0.08);
            // border-radius: 4px;
          }
          &-a.active {
            //  background: rgba(67,107,237,0.08);
            // border-radius: 4px;
          }
        }
        .nav {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          color: #333333;
          font-weight: 500;
          border-radius: 3px;
          padding: 5px 10px 5px 10px;
          transition: all ease-in 0.16s;
          margin-left: 16px;
          // &:hover {
          //   // background: #f7f7f8;
          //   background: #F2F2F2;
          // }
          // &.active {
          //   // background: #f7f7f8;
          //    background: #F2F2F2;
          // }
        }
      }
    }
  }
}
</style>
